import React from 'react';
import { ReactComponent as ErrorIcon } from '../Images/404.svg';
import './Notfound.css'; // Importing CSS file

const Notfound = () => (
    <div className="notfound-container">
        <h1 className="notfound-title">404</h1>
        <p className="notfound-message">Oops! The page you're looking for doesn't exist.</p>
        <div className="error-icon">
            <ErrorIcon />
        </div>
        <button className="back-home-button" onClick={() => window.location.href = '/'}>
            Back to Home
        </button>
    </div>
);

export default Notfound;

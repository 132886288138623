import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReactComponent as FigmaImg } from '../Images/figma.svg';
import { ReactComponent as XDImg } from '../Images/xd.svg';
import { ReactComponent as SketchImg } from '../Images/sk.svg';
import { ReactComponent as AIImg } from '../Images/ai.svg';
import { ReactComponent as PhotoshopImg } from '../Images/photoshop.svg';
import { ReactComponent as InvisionImg } from '../Images/invision.svg';
import { ReactComponent as AEImg } from '../Images/ae.svg';
import { ReactComponent as ANImg } from '../Images/an.svg';
import { ReactComponent as ZipImg } from '../Images/zep.svg';
import { ReactComponent as ACImg } from '../Images/ac.svg';
import '../Components/DevopsTab.css'
import { Col, Container, Row } from 'react-bootstrap';
import '../Components/UITabs.css'

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function UITabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                    <Tab label="UI/UX" {...a11yProps(0)} />
                    <Tab label="Prototyping" {...a11yProps(1)} />
                    <Tab label="Animations" {...a11yProps(2)} />
                    <Tab label="Colaboration" {...a11yProps(3)} />
                    <Tab label="Illustrator& Graphic Design" {...a11yProps(4)} />

                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className='UX-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <FigmaImg />
                                <h1>Figma</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <XDImg />
                                <h1>Adobe XD</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <SketchImg />
                                <h1>Sketch</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <AIImg />
                                <h1>Adobe Illustrator</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <PhotoshopImg />
                                <h1>Adobe Photoshop</h1>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div className='Prototyping-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <FigmaImg />
                                <h1>Figma</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <XDImg />
                                <h1>Adobe XD</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <SketchImg />
                                <h1>Sketch</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <InvisionImg />
                                <h1>Invision Studio</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <div className='animation-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <FigmaImg />
                                <h1>Figma</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <AEImg />
                                <h1>After Effects</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <ANImg />
                                <h1>Adobe Animate</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <div className='colaboration-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <ZipImg />
                                <h1>Zeplin</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <InvisionImg />
                                <h1> InVision</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <ACImg />
                                <h1>Adobe Cloud</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <div className='Illustrator-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <AIImg />
                                <h1>Illustrator</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <PhotoshopImg />
                                <h1>Photoshop</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <SketchImg />
                                <h1>Sketch</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
        </Box>
    );
}

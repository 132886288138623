import React from 'react';
import Slider from 'react-slick';
import '../Components/HomeBlogs.css';
import { ArrowForward, CloudQueue, AppSettingsAlt, SettingsSuggest, Layers, Code, Security } from '@mui/icons-material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomeBlogs() {
  const niches = [
    {
      title: "Serverless Computing",
      description: "Explore the benefits of serverless technologies for scalable and cost-efficient solutions.",
      icon: <CloudQueue className="niche-icon" />
    },
    {
      title: "Progressive Web Apps (PWAs)",
      description: "Learn about PWAs and how they enhance mobile and web accessibility with offline capabilities.",
      icon: <AppSettingsAlt className="niche-icon" />
    },
    {
      title: "Automated UI Testing with AI",
      description: "Discover how AI-driven tools streamline UI testing for enhanced quality assurance.",
      icon: <SettingsSuggest className="niche-icon" />
    },
    {
      title: "Microservices Architecture",
      description: "A modular approach to app development for improved scalability and maintainability.",
      icon: <Layers className="niche-icon" />
    },
    {
      title: "Infrastructure as Code (IaC)",
      description: "Automate infrastructure management with tools like Terraform and Ansible.",
      icon: <Code className="niche-icon" />
    },
    {
      title: "DevSecOps",
      description: "Integrate security into your DevOps pipeline for secure and compliant deployments.",
      icon: <Security className="niche-icon" />
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerPadding: '10px',
    responsive: [
      {
        breakpoint: 7680, // 8K
        settings: {
          slidesToShow: 6,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 3840, // 4K
        settings: {
          slidesToShow: 5,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 2560, // 1440p (WQHD, Quad HD)
        settings: {
          slidesToShow: 4,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 1920, // 1080p (Full HD)
        settings: {
          slidesToShow: 3,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 1280, // 720p (HD Ready)
        settings: {
          slidesToShow: 2,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '10px',
        },
      },
    ],
  };



  return (
    <div className="home-blogs-main">
      <div className="blogs-heading">
        <div className="blog-headings-details">
          <h1>Blogs</h1>
          <p>Stay up-to-date with the latest advancements and news in AI, machine learning, and data engineering by following our blog for insightful updates and expert content.</p>
        </div>
      </div>
      <div className="niche-slider">
        <Slider {...sliderSettings}>
          {niches.map((niche, index) => (
            <div key={index} className="niche-slide">
              {niche.icon}
              <h3>{niche.title}</h3>
              <p>{niche.description}</p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="blog-btn">
        <a href="https://nakwi.com/blog/" target="_self" rel="noopener noreferrer">
          <button id="blogs-heading-btn">
            Check Out Our Blogs <ArrowForward />
          </button>
        </a>
      </div>
    </div>
  );
}

export default HomeBlogs;

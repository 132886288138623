import { AccessTime, Build, Business, CheckCircle, Cloud, Computer, PhoneInTalk, PortableWifiOff, Star, } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import DevopsTabs from '../../Components/DevopsTabs';
import '../Services/Devops.css'
import ContactPage from '../../Components/ContactPage';
import ContactFooter from '../../Components/ContactFooter';
import { Link, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Devops() {

    const location = useLocation();

    // Scroll to the top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const cardData = [
        {
            title: 'Devising the Strategy',
            description: 'We tailor cloud development strategies to fit your business needs, offering specialized services like enterprise cloud adoption, CloudOps, and more. This ensures you stay ahead of your competitors in both strategy and execution.',
            icon: <Computer />
        },
        {
            title: 'Cloud App Development',
            description: 'Our cloud development solutions are both reliable and flexible, offering deployment options across public, private, or hybrid models. Additionally, we ensure peak performance optimization, helping you maximize overall revenue.',
            icon: <Business />
        },
        {
            title: 'Cloud Implementation & Migration Services',
            description: 'Our experienced developers specialize in all aspects of cloud migration and implementation, including multi-vendor ITIL processes and risk and compliance management. Collaborating with your IT team and service providers, our cloud app developers ensure a seamless and secure migration of applications, workflows, and infrastructures.',
            icon: <PortableWifiOff />
        },
        {
            title: 'Cloud Monitoring, Management, & Support',
            description: 'As a leading cloud application development company, we offer top-tier cloud performance monitoring and optimization services. To save you time, money, and effort, we provide a comprehensive 360-degree view of key factors, including SLA-based management and monthly efficiency audits.',
            icon: <Cloud />
        },
        {
            title: 'Cloud Security & Risk Management Services',
            description: 'There’s a common misconception that cloud-based solutions are completely risk-free. That’s where nakwi steps in. We provide integration services like cloud security assessments, risk and identity management, and technical security consultancy. Our goal is to give businesses like yours peace of mind by taking on the responsibility of cloud security and risk management.',
            icon: <Build />
        },
    ];

    return (
        <div className='devops-main'>
            <HelmetProvider>
                <Helmet>
                    <title>DevOps - Software Solutions - nakwi.com</title>
                </Helmet>
            </HelmetProvider>
            <div className='devops-main-container'>
                <div className='web-main-left'>
                    <h6>DevOps</h6>
                    <h1>Scalable Cloud Solutions Tailored to Elevate Your Business and Enhance User Satisfaction!</h1>
                    <p>nakwi offers cutting-edge cloud app development services to empower its clients' businesses for future growth. We safeguard their investments by equipping them to adapt to evolving technology standards.</p>
                </div>
                <div className='main-left-inside'>
                    <Container>
                        <Row>
                            <Col lg='6'>
                                <span><AccessTime /></span>
                                <p>Global Time-Zone Alignment</p>
                            </Col>
                            <Col lg='6'>
                                <span><PhoneInTalk /></span>
                                <p>Complimentary Consultation</p>
                            </Col>
                            <Col lg='6'>
                                <span><Star /></span>
                                <p>Over 8 Years of Experience</p>
                            </Col>
                            <Col lg='6'>
                                <span><CheckCircle /></span>
                                <p>Over 120 Successful Projects</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/* web service */}
            <div className='mobile-services-main'>
                <div className='web-services-details'>
                    <h1>Cloud Application Development Services</h1>
                    <p>Cloud application development is a fusion of multiple independent services, and fortunately, nakwi specializes in each of them.</p>
                </div>
                <div className='web-services-container'>
                    <Container>
                        <Row>
                            {cardData.map((card, index) => (
                                <Col lg='4' key={index}>
                                    <span>{card.icon}</span>
                                    <h2>{card.title}</h2>
                                    <hr />
                                    <p>{card.description}</p>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <div className='web-services-butoon'>
                        <Link to='/quote' ><button>Contact Us</button></Link>
                    </div>
                </div>
            </div>
            {/* Tabs  */}
            <div className='cloud-development'>
                <DevopsTabs />
            </div>

            {/* devops box */}

            <div className='devops-box-main'>
                <div className='devops-box-details'>
                    <Container>
                        <Row>
                            <Col>
                                <img src={require('../../Images/cloud.webp')} alt='' />
                            </Col>
                            <Col>
                                <div className='devops-box-inside'>
                                    <h1>Create your cloud app with nakwi today!</h1>
                                <Link to='/quote'><button>Drop Us a Line</button></Link>    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div>
                <ContactPage />
                <ContactFooter />
            </div>

        </div >
    )
}

export default Devops;

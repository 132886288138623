import { ArrowForward } from '@mui/icons-material'
import React from 'react'
import './Home.css'
import { Col, Container, Row } from 'react-bootstrap'
import Homesection2 from '../Components/Homesection2'
import HomeSection3 from '../Components/HomeSection3'
import HomeBlogs from '../Components/HomeBlogs'
import ContactPage from '../Components/ContactPage'
import ContactFooter from '../Components/ContactFooter'
import { ReactComponent as RocketIcon } from '../Images/rocket.svg'
import { ReactComponent as UFOIcon } from '../Images/ufo.svg'
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'



function Home() {
  return (
    <>
      <div className='Home'>
        <HelmetProvider>
          <Helmet>
            <title>Software Solutions - nakwi.com</title>
          </Helmet>
        </HelmetProvider>
        <div className='Home-container'>
          <div className='border-style1'>
            <UFOIcon />
          </div>
          <Container>
            <Row>
              <Col lg='12'>
                <div className='Heading'>
                  <h4>End-to-End Services: Design, Development and Infrastructure</h4>
                  <p>We provide complete solutions from design and development to infrastructure management,
                    delivering tailored websites, applications, and secure cloud systems for your business needs.</p>
                  <Link to='/quote'><button>Get In Touch <ArrowForward /></button></Link>
                </div>
              </Col>
            </Row>
          </Container>
          <div className='border-style'>
            <RocketIcon />
          </div>
        </div>
      </div>
      <div className='Homesection2'>
        <Homesection2 />
      </div>


      <div className='Homesection3'>
        <HomeSection3 />
      </div>
      <div className='Homesection3'>
        <HomeBlogs />
      </div>
      <div className='contactpage'>
        <ContactPage />
      </div>
      <div className='footer'>
        <ContactFooter />
      </div>
    </>
  )
}

export default Home

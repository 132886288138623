import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import '../Components/DevopsTab.css'
import { Col, Container, Row } from 'react-bootstrap';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function DevopsTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                    <Tab label="Amazone Web Services" {...a11yProps(0)}
                        icon={<img src="https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-icon.svg" alt='' className="tab-logo" />}
                    />

                    <Tab label="MicroSoft Azure" {...a11yProps(1)}
                        icon={<img src="https://www.vectorlogo.zone/logos/microsoft_azure/microsoft_azure-icon.svg" alt='' className="tab-logo" />}
                    />
                    <Tab label="Google Cloud" {...a11yProps(2)}
                        icon={<img src="https://www.vectorlogo.zone/logos/google_cloud/google_cloud-icon.svg" alt='' className="tab-logo" />}
                    />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className='amazone-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-ar21.svg' alt=''/></Col>
                            <Col lg='4' md='3' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_awslambda/amazon_awslambda-ar21.svg'alt='' /></Col>
                            <Col lg='4' md='3' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_cloudwatch/amazon_cloudwatch-ar21.svg' alt=''/></Col>
                            <Col lg='4' md='3' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_ecs/amazon_ecs-ar21.svg' alt=''/></Col>
                            <Col lg='4' md='3' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_eks/amazon_eks-ar21.svg' alt='' /></Col>
                            <Col lg='4' md='3' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_cloudformation/amazon_cloudformation-ar21.svg' alt='' /></Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div className='azure-main'>
                    <Container>
                        <Row>
                            <Col lg='3' md='4' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-ar21.svg' alt=''/></Col>
                            <Col lg='3' md='4' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_awslambda/amazon_awslambda-ar21.svg' alt='' /></Col>
                            <Col lg='3' md='4' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_ecs/amazon_ecs-ar21.svg' alt='' /></Col>
                            <Col lg='3' md='4' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_cloudformation/amazon_cloudformation-ar21.svg' alt='' /></Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
            <div className='google-main'>
                    <Container>
                        <Row>
                            <Col lg='6' md='4' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-ar21.svg' alt=''/></Col>
                            <Col lg='6' md='4' xs='6'><img src='https://www.vectorlogo.zone/logos/amazon_cloudformation/amazon_cloudformation-ar21.svg' alt=''/></Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
        </Box>
    );
}

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../Components/HomeSection2.css'

import { ReactComponent as webDevImg } from '../Images/web.svg';
import { ReactComponent as mobileDevImg } from '../Images/mobile.svg';
import { ReactComponent as devOpsImg } from '../Images/devops.svg';
import { ReactComponent as infrastructureImg } from '../Images/ui.svg';
import { ReactComponent as uiuxImg } from '../Images/infra.svg';
import { ReactComponent as qaImg } from '../Images/qa.svg'
import { useLocation, useNavigate } from 'react-router-dom';

const SoftDevCards = () => {
    const [activeCard, setActiveCard] = useState('Web Dev');
    const navigate = useNavigate();
    const location = useLocation();

    // Scroll to the top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const cardDetails = {
        'Web Dev': {
            image: webDevImg,
            title: 'Web App Development',
            description: 'Transform your digital vision into reality with nakwi.',
            technologies: [
                { name: 'Node Js', logo: 'https://www.vectorlogo.zone/logos/nodejs/nodejs-icon.svg' },
                { name: 'React Js', logo: 'https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg' },
                { name: 'Vue Js', logo: 'https://www.vectorlogo.zone/logos/vuejs/vuejs-icon.svg' },
                { name: 'Python', logo: 'https://www.vectorlogo.zone/logos/python/python-icon.svg' },
                { name: 'Django', logo: 'https://www.vectorlogo.zone/logos/nodejs/nodejs-icon.svg' },
                { name: 'Angular', logo: 'https://www.vectorlogo.zone/logos/angular/angular-icon.svg' },
                { name: 'Laravel', logo: 'https://www.vectorlogo.zone/logos/laravel/laravel-icon.svg' },
                { name: 'Ruby on Rails', logo: 'https://www.vectorlogo.zone/logos/ruby-lang/ruby-lang-icon.svg' },
            ]
        },
        'Mobile Dev': {
            image: mobileDevImg,
            title: 'Mobile Development',
            description: 'Custom Android & iOS app development experts.',
            technologies: [
                { name: 'React Native', logo: 'https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg' },
                { name: 'Flutter', logo: 'https://www.vectorlogo.zone/logos/flutterio/flutterio-icon.svg' },
                { name: 'Swift', logo: 'https://www.vectorlogo.zone/logos/swift/swift-icon.svg' },
                { name: 'Kotlin', logo: 'https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg' },
                { name: 'Java', logo: 'https://www.vectorlogo.zone/logos/java/java-icon.svg' }
            ]
        },
        'DevOps': {
            image: devOpsImg,
            title: 'DevOps',
            description: 'Optimize development with streamlined DevOps practices.',
            technologies: [
                { name: 'Docker', logo: 'https://www.vectorlogo.zone/logos/docker/docker-icon.svg' },
                { name: 'Kubernetes', logo: 'https://www.vectorlogo.zone/logos/kubernetes/kubernetes-icon.svg' },
                { name: 'Ansible', logo: 'https://www.vectorlogo.zone/logos/ansible/ansible-icon.svg' },
                { name: 'Terraform', logo: 'https://www.vectorlogo.zone/logos/hashicorp/hashicorp-icon.svg' },
                { name: 'Jenkins', logo: 'https://www.vectorlogo.zone/logos/jenkins/jenkins-icon.svg' },
                { name: 'Git', logo: 'https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg' },
                { name: 'CircleCI', logo: 'https://www.vectorlogo.zone/logos/circleci/circleci-icon.svg' },
                { name: 'AWS', logo: 'https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-icon.svg' },
                { name: 'GitLab CI', logo: 'https://www.vectorlogo.zone/logos/gitlab/gitlab-icon.svg' },
            ]
        },
        'Infrastructure': {
            image: infrastructureImg,
            title: 'Infrastructure',
            description: 'Build and manage your IT infrastructure to ensure smooth deployment and scalability.',
            technologies: [
                { name: 'AWS', logo: 'https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-icon.svg' },
                { name: 'Azure', logo: 'https://www.vectorlogo.zone/logos/microsoft_azure/microsoft_azure-icon.svg' },
                { name: 'VMware', logo: 'https://upload.wikimedia.org/wikipedia/commons/3/34/VMware_Workstation_11.0_icon.png' },
                { name: 'GCP', logo: 'https://static-00.iconduck.com/assets.00/google-cloud-icon-2048x1646-7admxejz.png' },

            ]
        },
        'UI/UX Design': {
            image: uiuxImg,
            title: 'UI/UX Design',
            description: 'Design stunning user interfaces and experiences that captivate users.',
            technologies: [
                { name: 'Figma', logo: 'https://www.vectorlogo.zone/logos/figma/figma-icon.svg' },
                { name: 'Adobe XD', logo: 'https://seeklogo.com/images/A/adobe-xd-logo-64364E3A24-seeklogo.com.png' },
                { name: 'Sketch', logo: 'https://www.vectorlogo.zone/logos/sketchapp/sketchapp-icon.svg' },
                { name: 'Invision', logo: 'https://www.vectorlogo.zone/logos/invisionapp/invisionapp-icon.svg' },
                { name: 'Adobe After Effects', logo: 'https://seeklogo.com/images/A/after-effects-cc-logo-BCC55BAFF7-seeklogo.com.png' },
                { name: 'Adobe Illustrator', logo: 'https://www.vectorlogo.zone/logos/adobe_illustrator/adobe_illustrator-icon.svg' },
                { name: 'Adobe Photoshop', logo: 'https://seeklogo.com/images/A/adobe-photoshop-cc-logo-CBD0AAA3A7-seeklogo.com.png' },
                { name: 'Balsamiq', logo: 'https://www.vectorlogo.zone/logos/balsamiq/balsamiq-icon.svg' },
            ]
        },

        'QA & Testing': {
            image: qaImg,
            title: 'QA & Testing',
            description: 'Ensure quality with our comprehensive testing strategies across various platforms.',
            technologies: [
                { name: 'Selenium', logo: 'https://cdn.icon-icons.com/icons2/3915/PNG/512/selenium_logo_icon_249659.png' },
                { name: 'Jest', logo: 'https://www.vectorlogo.zone/logos/jestjsio/jestjsio-icon.svg' },
                { name: 'Jira', logo: 'https://www.vectorlogo.zone/logos/atlassian_jira/atlassian_jira-icon.svg' },
                { name: 'JMeter', logo: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/JMeter_Logo.png' },
                { name: 'Postman', logo: 'https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg' },
                { name: 'TravisCI', logo: 'https://www.vectorlogo.zone/logos/travis-ci/travis-ci-icon.svg' },
                { name: 'OWASP ZAP', logo: 'https://cdn.icon-icons.com/icons2/3913/PNG/512/owasp_logo_icon_248268.png' },
                { name: 'Mocha', logo: 'https://www.vectorlogo.zone/logos/mochajs/mochajs-icon.svg' },
                { name: 'Appium', logo: 'https://seeklogo.com/images/A/appium-logo-2AB368AF4A-seeklogo.com.png' },
            ]
        },

    };

    const handleCardClick = (cardTitle) => {
        setActiveCard(cardTitle);
    };

    const handleLearnMoreClick = () => {
        const pathMap = {
            'Web Dev': '/web-development',
            'Mobile Dev': '/mobile-development',
            'DevOps': '/devops',
            'Infrastructure': '/infrastructure',
            'UI/UX Design': '/uiux-design',
            'QA & Testing': '/qa-testing'
        };

        const path = pathMap[activeCard];
        if (path) {
            navigate(path);
        } else {
            console.error("No path found for the active card:", activeCard);
        }
    };

    return (
        <div className='soft-dev-details'>
            <Container>
                <Row>
                    <div className="soft-dev-heading">
                        <h1>Holistic Software Development: From UX Design to Infrastructure Management</h1>
                        <p>Leveraging our deep expertise in software solutions, we turn your groundbreaking ideas into reality with unmatched speed and precision.</p>
                    </div>
                </Row>
            </Container>
            <div className='soft-dev-cards'>
                <Container>
                    <Row>
                        {Object.keys(cardDetails).map((cardTitle, index) => (
                            <Col lg='2' md='4' sm='6' xs='6' key={index}>
                                <div
                                    className={`inner-card ${activeCard === cardTitle ? 'active' : ''}`}
                                    onClick={() => handleCardClick(cardTitle)}
                                >
                                    {React.createElement(cardDetails[cardTitle].image)}
                                    <h5>{cardTitle}</h5>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <div className='soft-details'>
                                <h1 id='soft-details-heading'>{cardDetails[activeCard].title}</h1>
                                <hr />
                                <p id='soft-details-para'>{cardDetails[activeCard].description}</p>
                                <div id='soft-details-lang'>
                                    {cardDetails[activeCard].technologies.map((tech, index) => (
                                        <div key={index} className="lang-logo">
                                            <img height="40" width="40" src={tech.logo} alt={tech.name} />
                                            <p>{tech.name}</p>
                                        </div>
                                    ))}
                                </div>
                                <button id='soft-details-btn' onClick={handleLearnMoreClick}>Learn More</button>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default SoftDevCards;

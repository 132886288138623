import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ReactComponent as WebDevImg } from '../Images/web.svg';
import { ReactComponent as MobileDevImg } from '../Images/mobile.svg';
import { ReactComponent as DevOpsImg } from '../Images/devops.svg';
import { ReactComponent as InfrastructureImg } from '../Images/ui.svg';
import { ReactComponent as UiuxImg } from '../Images/infra.svg';
import { ReactComponent as QaImg } from '../Images/qa.svg';
import { ReactComponent as NakwiSvg } from '../Images/nakwi-logo.svg';
import '../Components/TopNavbar.css';
import { Link } from 'react-router-dom';
import { InfoOutlined, Work } from '@mui/icons-material';

function TopNavbar() {
    const [expanded, setExpanded] = useState(false);

    const handleLinkClick = () => {
        setExpanded(false); 
    };

    return (
        <Navbar 
            expand="lg" 
            className="custom-navbar" 
            expanded={expanded} 
            onToggle={(isOpen) => setExpanded(isOpen)}
        >
            <Container>
                <div className="topnav-logo">
                    <Link to='/' onClick={handleLinkClick}>
                        <NakwiSvg />
                    </Link>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" onClick={handleLinkClick}>
                            Home
                        </Nav.Link>

                        <div className="dropdown-container">
                            <NavDropdown
                                title="Services"
                                id="services-dropdown"
                            >
                                <NavDropdown.Item as={Link} to="/web-development" onClick={handleLinkClick}>
                                    <WebDevImg /> Web Development
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/mobile-development" onClick={handleLinkClick}>
                                    <MobileDevImg /> Mobile Development
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/devops" onClick={handleLinkClick}>
                                    <DevOpsImg /> DevOps
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/infrastructure" onClick={handleLinkClick}>
                                    <InfrastructureImg /> Infrastructure
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/uiux-design" onClick={handleLinkClick}>
                                    <UiuxImg /> UI/UX Design
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/qa-testing" onClick={handleLinkClick}>
                                    <QaImg /> QA & Testing
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>

                        <div className="dropdown-container2">
                            <NavDropdown title="Company" id="company-dropdown">
                                <NavDropdown.Item as={Link} to="/about" onClick={handleLinkClick}>
                                    <InfoOutlined id='infoicon' /> About Us
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/career' onClick={handleLinkClick}>
                                    <Work id='infoicon'/> Career
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </Nav>
                    <div className="button-container">
                      <Link to='/quote' onClick={handleLinkClick}>
                        <button>Get a Quote</button>
                      </Link>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNavbar;

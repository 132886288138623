import React from 'react';
import '../Components/HomeSection3.css';
import SearchIcon from '@mui/icons-material/Search';
import PaletteIcon from '@mui/icons-material/Palette';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import BuildIcon from '@mui/icons-material/Build';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Col, Container, Row } from 'react-bootstrap';

function HomeSection3() {

    const points = [
        { icon: <SearchIcon />, title: "Analysis", description: "A thorough analysis is performed with the help of requirement elicitation.", color: "#4B0082" },
        { icon: <PaletteIcon />, title: "Design", description: "The design phase focuses on creating a structured and efficient architecture.", color: "#FF8C00" },
        { icon: <DataUsageIcon />, title: "Extract", description: "Data extraction is performed to retrieve the most valuable data.", color: "#008B8B" },
        { icon: <BuildIcon />, title: "Automate", description: "We automate processes to increase efficiency and reduce manual intervention.", color: "#d2042d" },
        { icon: <LaptopMacIcon />, title: "Develop", description: "Our development team brings the project to life, writing clean, scalable code.", color: "#006400" },
        { icon: <RocketLaunchIcon />, title: "Accelerate", description: "We accelerate the project delivery by optimizing workflows.", color: "#8B008B" },
    ];


    return (
        <div className='work-flow-main'>
            <Container>
                <Row>
                    <Col>
                        <div className="workflow-template">
                            <h1>Our Workflow</h1>
                            <p>We pride ourselves on our unwavering commitment to delivering innovative AI/ML solutions and data-centric services. Our approach emphasizes diligence and precision at every stage of product engineering and development, ensuring exceptional results for our valued clients.</p>
                            <div className="points-container">
                                {points.map((point, index) => (
                                    <div key={index} className="point-item">
                                        <div className="point-icon" style={{ borderColor: point.color }}>
                                            {point.icon}
                                        </div>
                                        <div className="point-content" style={{ backgroundColor: point.color , border:'2px solid #06211E' }}>
                                            <div className="pointHead">
                                                <h3>{point.title}</h3>
                                            </div>
                                            <hr/>
                                            <div className="pointDes">
                                                <p>{point.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HomeSection3;

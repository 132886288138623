import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import '../Components/Technologytab.css'
import { ReactComponent as Cypress } from '../Images/cypress.svg'
import { ReactComponent as Vscode } from '../Images/1.svg'
import { ReactComponent as UFT } from '../Images/hp-uft.svg'
import { ReactComponent as SIKULI } from '../Images/sikuli.svg'
import { ReactComponent as RONOROX } from '../Images/Ranorex.svg'
import { ReactComponent as JMETER } from '../Images/jmeter.svg'
import { ReactComponent as Blaze } from '../Images/blaze.svg'
import { ReactComponent as Gatling } from '../Images/gatling.svg'
import { ReactComponent as Protector } from '../Images/protector.svg'
import { ReactComponent as Selenium } from '../Images/selenium.svg'
import { ReactComponent as Load } from '../Images/load.svg'
import { ReactComponent as Appium } from '../Images/appium.svg'
import { ReactComponent as Robo } from '../Images/robo.svg'
import { Col, Container, Row } from 'react-bootstrap';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TechnologyTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                    <Tab label="Web" {...a11yProps(0)} />
                    <Tab label="Desktop" {...a11yProps(1)} />
                    <Tab label="Performance" {...a11yProps(2)} />
                    <Tab label="Mobile" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className='web-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <Selenium />
                                <h1>Selenium</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <Protector />
                                <h1>Protractor</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <Cypress />
                                <h1>Cypress.io</h1>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div className='desktop-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <Vscode />
                                <h1>VS Code</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <UFT />
                                <h1>HP UFT</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <SIKULI />
                                <h1>Sikuli Script</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <RONOROX />
                                <h1>Ranorex</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <div className='Performance-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <JMETER />
                                <h1>Apache JMeter</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <Blaze />
                                <h1>BlazeMeter</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <Gatling />
                                <h1>Gatling</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <Load />
                                <h1>Load Runner</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <div className='Mobile-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <Appium />
                                <h1>Appium</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <Robo />
                                <h1> Robotium</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <SIKULI />
                                <h1>Silk Test</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <Cypress />
                                <h1>Cypress.io</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
        </Box>
    );
}

import { Handshake, Message, ThumbUp, Code, People, Verified, Settings, Support, Visibility, Business, Timeline, Groups } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../Pages/AboutUs.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ReactComponent as NakwiSvg } from '../Images/nakwi-logo.svg';
import { ReactComponent as ValueSvg } from '../Images/value.svg'
import ContactPage from '../Components/ContactPage';
import ContactFooter from '../Components/ContactFooter';

function AboutUs() {

    const counterAnimation = () => {
        const counters = document.querySelectorAll('.counter');

        counters.forEach(counter => {
            const target = +counter.getAttribute('data-target');
            const increment = target / 200;

            const updateCounter = () => {
                const current = +counter.innerText;
                if (current < target) {
                    counter.innerText = `${Math.ceil(current + increment)}`;
                    setTimeout(updateCounter, 50);
                } else {
                    counter.innerText = target;
                }
            };
            updateCounter();
        });
    };

    useEffect(() => {
        counterAnimation();
    }, []);

    return (
        <div className='aboutus-main'>
            <HelmetProvider>
                <Helmet>
                    <title>About Us - Software Solutions - nakwi.com</title>
                </Helmet>

                <div className='about-us-heading'>
                    <h5>About Us</h5>
                    <h1>We Acknowledge. We Interpret.We Deliver Effectively.</h1>
                    <p id='aboutus-mainpara'>We deliver cutting-edge software solutions that empower organizations to navigate modern technological advancements, enabling them to thrive in today’s competitive landscape.</p>
                    <span><NakwiSvg /></span>
                </div>
                <Container fluid>
                    <Row className="text-center">
                        <Col lg="3" md="6" xs="6" className="mb-4">
                            <div className="aboutus-counting-section">
                                <div className="aboutus-counting-details">
                                    <h4>
                                        <People className="svg-icon" />
                                        <span className="counter" data-target="200">0</span>+<br />Clients
                                    </h4>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" md="6" xs="6" className="mb-4">
                            <div className="aboutus-counting-section">
                                <div className="aboutus-counting-details">
                                    <h4>
                                        <Groups className="svg-icon" />
                                        <span className="counter" data-target="15">0</span>+<br />Team Size
                                    </h4>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" md="6" xs="6" className="mb-4">
                            <div className="aboutus-counting-section">
                                <div className="aboutus-counting-details">
                                    <h4>
                                        <Timeline className="svg-icon" />
                                        <span className="counter" data-target="1500">0</span>+<br />Agile Sprints
                                    </h4>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" md="6" xs="6" className="mb-4">
                            <div className="aboutus-counting-section">
                                <div className="aboutus-counting-details">
                                    <h4>
                                        <Business className="svg-icon" />
                                        <span className="counter" data-target="1">0</span><br />Dev Centers
                                    </h4>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>


                <div className='about-whyus-section'>
                    <h4>Why Us?</h4>
                    <p>nakwi is an emerging software development firm that provides innovative technological solutions globally, combining cutting-edge tech stacks and artificial intelligence with human expertise to serve its diverse clientele.</p>
                    <Container>
                        <Row>
                            <Col lg='4'>
                                <div className='whyus-cards'>
                                    <Message />
                                    <h4>Effective Communication</h4>
                                    <hr />
                                    <p>At nakwi, we prioritize communication to ensure our clients feel at ease. We thoroughly discuss and understand every detail of the project, maintaining close supervision throughout to guarantee satisfaction.</p>
                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className='whyus-cards'>
                                    <Verified />
                                    <h4>Quality Assurance</h4>
                                    <hr />
                                    <p>We deliver high-quality solutions that not only meet but exceed our clients’ expectations. Our commitment to excellence ensures reliability and performance in every project.</p>
                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className='whyus-cards'>
                                    <People />
                                    <h4>Team Collaboration</h4>
                                    <hr />
                                    <p>Collaboration is at the core of our success. We work seamlessly together, ensuring efficient teamwork between our internal teams and with our clients.</p>
                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className='whyus-cards'>
                                    <Settings />
                                    <h4>Competent Management</h4>
                                    <hr />
                                    <p>Our management ensures that every aspect of the project is handled efficiently and competently, maintaining the highest standards throughout the development process.</p>
                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className='whyus-cards'>
                                    <Support />
                                    <h4>Support & Commitment</h4>
                                    <hr />
                                    <p>We provide ongoing support and maintain a strong commitment to ensuring our clients receive the best service and solutions during and after the project lifecycle.</p>
                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className='whyus-cards'>
                                    <Visibility />
                                    <h4>Attention to Detail</h4>
                                    <hr />
                                    <p>Our meticulous attention to detail guarantees that every feature and aspect of the project is carefully considered to meet the highest quality standards.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='aboutus-benefit-section'>
                    <h1>How Can You Benefit From Our Services?</h1>
                    <p>By transferring the responsibility of software project development to Folium AI, you can enjoy the following advantages.</p>
                    <Container>
                        <Row>
                            <Col lg='4'>
                                <span>01</span>
                                <h3>Ensure Impactful Innovations</h3>
                                <p>The software solutions we provide are not only significant but also impactful, ensuring you stand out in a competitive landscape.</p>
                            </Col>
                            <Col lg='4'>
                                <span>02</span>
                                <h3>Enhanced Flexibility</h3>
                                <p>Our scalable solutions adapt to your evolving business needs, ensuring you stay ahead in a dynamic market.</p>
                            </Col>
                            <Col lg='4'>
                                <span>03</span>
                                <h3>Expert Guidance</h3>
                                <p>With our team's expertise, you can navigate technological challenges and achieve your strategic objectives.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='aboutus-value-section'>
                    <div className='aboutus-value-heading'>
                        <h1>Company Values</h1>
                        <p>In the realm of software solution provision, companies encounter various challenges on their quest for true success. We are committed to navigating these challenges by adhering to our established values and ethical principles.</p>
                    </div>
                    <div className='aboutus-value-details'>
                        <Container>
                            <Row>
                                <Col lg='6'>
                                    <h4><Handshake /> Empathy</h4>
                                    <p>We strive to create a productive, enjoyable, and respectful work environment, as our primary goal is to ensure ease and comfort for everyone.</p>
                                    <h4><ThumbUp /> Quality</h4>
                                    <p>As a team, we refuse to settle for “good enough.” Our commitment to our clients drives us to pursue excellence in everything we do.</p>
                                    <h4><Code /> Transparency</h4>
                                    <p>We operate with utmost honesty and commitment to our clients, ensuring that all our processes remain fully transparent and scalable.</p>
                                    <h4><People /> Collaboration</h4>
                                    <p>We ensure our clients are actively engaged throughout all stages of development through comprehensive team collaboration.</p>
                                </Col>
                                <Col lg='6'>
                                    <div className='value-img'>
                                        <ValueSvg />
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div>
                    <ContactPage />
                    <ContactFooter />
                </div>
            </HelmetProvider>
        </div>
    )
}

export default AboutUs;

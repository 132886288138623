// import { AccessTime, CheckCircle, PhoneInTalk, Star } from '@mui/icons-material'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Components/Career.css'
import { ReactComponent as CareerSvg } from '../Images/career.svg'
import ContactFooter from './ContactFooter';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Career() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        resume: null,
    });

    // Handle input change for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle file input for resume upload
    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            resume: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataObj = new FormData();
        formDataObj.append('name', formData.name);
        formDataObj.append('email', formData.email);
        formDataObj.append('phone', formData.phone);
        formDataObj.append('resume', formData.resume);

        fetch('save_resume.php', {
            method: 'POST',
            body: formDataObj,
        })
            .then(response => response.text())
            .then(data => {
                console.log(data);
                alert("Form submitted successfully!");
            })
            .catch(error => {
                console.error('Error:', error);
                alert("Failed to submit the form.");
            });
    };

    return (
        <div className='Career-main'>
            <HelmetProvider>
                <Helmet>
                    <title>Career - Software Solutions - nakwi.com</title>
                </Helmet>

                <div className='career-main-container'>
                    <Container>
                        <Row>
                            <Col lg='6'>
                                <div className='web-main-left'>
                                    <h1>Start Your New Journey With Us And Be A Part Of Our Mission</h1>
                                    <p>Success isn't measured by what you accomplish, but by the challenges you conquer along the way.</p>
                                </div>
                                {/* <div className='main-left-inside'>
                                <Container>
                                    <Row>
                                        <Col lg='6'>
                                            <span><AccessTime /></span>
                                            <p>Global Time-Zone Alignment</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><PhoneInTalk /></span>
                                            <p>Complimentary Consultation</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><Star /></span>
                                            <p>Over 8 Years of Experience</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><CheckCircle /></span>
                                            <p>Over 120 Successful Projects</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </div> */}
                            </Col>
                            <Col lg='6'>
                                <div className="img-main">
                                    <CareerSvg />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <hr />
                <div className="career-form">
                    <div className="form-container">
                        <h2>Upload Your Resume</h2>
                        <form onSubmit={handleSubmit} className="resume-form">
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="phone">Phone Number:</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}

                                    placeholder="123-456-7890"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="resume">Upload Resume:</label>
                                <input
                                    type="file"
                                    id="resume"
                                    name="resume"
                                    accept=".pdf,.doc,.docx"
                                    onChange={handleFileChange}
                                    required
                                />
                            </div>

                            <button type="submit" className="submit-btn">Submit</button>
                        </form>
                    </div>
                </div>

                <div className="career-footer-section">
                    <ContactFooter />
                </div>
            </HelmetProvider>
        </div>
    )
}

export default Career;

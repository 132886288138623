import { AccessTime, CheckCircle, PhoneInTalk, Star } from '@mui/icons-material'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Services/Infra.css'
import ContactFooter from '../../Components/ContactFooter'
import ContactPage from '../../Components/ContactPage'
import { Helmet, HelmetProvider } from 'react-helmet-async'


function InfraDevelopment() {
    return (
        <div className='infra-main'>
            <HelmetProvider>
                <Helmet>
                    <title>Infrastructure - Software Solutions - nakwi.com</title>
                </Helmet>
            </HelmetProvider>
            <div className='qa-main-container'>
                <div className='web-main-left'>
                    <h6>Infrastructure</h6>
                    <h1>Optimizing Performance and Scalability with Robust Infrastructure Solutions.</h1>
                    <p>nakwi focuses on building reliable infrastructure tailored to meet modern business demands. By integrating cutting-edge technologies and high-performance systems, we ensure that your digital platforms operate seamlessly, scale efficiently, and maintain security, driving long-term growth and operational efficiency for your business.</p>
                </div>
                <div className='main-left-inside'>
                    <Container>
                        <Row>
                            <Col lg='6'>
                                <span><AccessTime /></span>
                                <p>Global Time-Zone Alignment</p>
                            </Col>
                            <Col lg='6'>
                                <span><PhoneInTalk /></span>
                                <p>Complimentary Consultation</p>
                            </Col>
                            <Col lg='6'>
                                <span><Star /></span>
                                <p>Over 8 Years of Experience</p>
                            </Col>
                            <Col lg='6'>
                                <span><CheckCircle /></span>
                                <p>Over 120 Successful Projects</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/* footer  */}

            <div className='qa-footer'>
                <ContactPage />
                <ContactFooter />
            </div>
        </div>
    )
}

export default InfraDevelopment
